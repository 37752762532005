<template>
  <v-card class="mt-12 mb-16 mx-auto" elevation="12" max-width="461" outlined>
    <amplify-authenticator v-show="activated" :auth-config="authConfig" />
    <div v-if="!activated" class="text-center pt-12 pb-16">
      <h4 class="mb-16">Loading Account Form</h4>
      <br />
      <br />
      <v-progress-circular class="mt-16" indeterminate color="primary" />
      <br />
      <br />
      <br />
      <br />
    </div>
  </v-card>
</template>

<script>
import { AmplifyEventBus, components } from "aws-amplify-vue";
import { camelCase, paramCase } from "change-case";
import accountMixin from "@/mixins/accounts.js";

const authConfig = {};
export default {
  name: "Account",
  metaInfo: { title: "Account" },
  components: { ...components },
  mixins: [accountMixin],
  props: {
    id: {
      type: String,
      default: "account",
    },
    target: {
      type: String,
    },
  },

  data() {
    return {
      inProcess: false,
      activated: false,
      authConfig,
    };
  },
  computed: {
    currentRoute() {
      return this.$route;
    },
  },
  watch: {
    target: function (val) {
      this.initAuth(val);
    },
  },
  created() {
    AmplifyEventBus.$on("authState", this.processAuthEvent);
  },
  mounted() {
    const self = this;
    setTimeout(() => self.initAuth(self.target), 2000);
  },
  methods: {
    initAuth(val) {
      const authState = camelCase(val);
      AmplifyEventBus.$emit("authState", authState);
    },

    processAuthEvent(e) {
      if (this.inProcess) return;
      let path;
      this.inProcess = true;

      this.activated = true;
      console.log("processAuthEvent e", e);
      const validPaths = [
        "signIn",
        "signUp",
        "confirmSignUp",
        "confirmSignIn",
        "forgotPassword",
        "requireNewPassword",
      ];
      path =
        e === "signedIn"
          ? "/member/dashboard"
          : typeof e === "string" && validPaths.find((x) => x === e)
          ? `/account/${paramCase(e)}`
          : null;
      console.log("processAuthEvent path", path);
      this.$nextTick(() => {
        if (this.$route.path !== path) this.$router.push(path);
        this.inProcess = false;
      });
    },
  },
};
</script>
